import React from "react"
import { Link } from "gatsby"
import { useQuery } from "@apollo/react-hooks"
import { RESILIENCE_TEST, Journey } from "./../gql"
import LayoutContainer from "./../components/layout"
import Throbber from "./../components/throbber"
import ResilienceResultComponent from "./../components/resilience-result-component"
import RecommendedJourneys from "./../components/recommended-journeys"
import { Trans, withTranslation } from "react-i18next"

const ResilienceResult = ({ location }) => {
  const { nodes: allJourneys } = Journey()
  const { loading, error, data: questionsData } = useQuery(RESILIENCE_TEST, {
    fetchPolicy: "cache-and-network",
  })
  // const categoriesOrder = new Set(["Body", "Heart", "Mind", "Spirit"])

  if (loading)
    return (
      <LayoutContainer seoTitle="Resilience test" path={location.pathname}>
        <Throbber loadingText="Loading result..." relative />
      </LayoutContainer>
    )

  if (error)
    return (
      <LayoutContainer seoTitle="Resilience test" path={location.pathname}>
        <div className="center-align">
          <p className="text">{error.message}</p>
          <Link className="button" to="/login">
          <Trans i18nKey={"resilienceresult.login_button"} />
            
          </Link>
        </div>
      </LayoutContainer>
    )

  const { resilienceTest } = questionsData

  return (
    <LayoutContainer seoTitle="Resilience test result" path={location.pathname}>
      <ResilienceResultComponent
        resilienceTest={resilienceTest}
        allJourneys={allJourneys}
      />
      <div className="margin--top-100">
        <RecommendedJourneys
          resilienceTest={resilienceTest}
          allJourneys={allJourneys}
        />
      </div>
    </LayoutContainer>
  )
}

export default withTranslation()(ResilienceResult)
